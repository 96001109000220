import Countdown, { zeroPad } from "react-countdown";
import AOS from "aos";

import headerTop from "./assets/img/header-top.svg";
import headerBottom from "./assets/img/header-bottom.svg";
import headerLeft from "./assets/img/header-left.svg";
import headerRight from "./assets/img/header-right.png";
import history from "./assets/img/history.svg";
import bnb from "./assets/img/bnb.svg";
import clock from "./assets/img/clock.svg";
import eth from "./assets/img/eth.svg";
import uni from "./assets/img/uniswap.svg";
import circle from "./assets/img/circle.svg";
import usdt from "./assets/img/usdt.svg";
import usdtv2 from "./assets/img/usdt-v2.svg";
import rocket from "./assets/img/rocket.svg";
import initial from "./assets/img/initial.svg";
import worth from "./assets/img/worth.svg";
import matic from "./assets/img/matic.svg";
import bsc from "./assets/img/bsc.svg";
import arb from "./assets/img/arb.svg";
import ftm from "./assets/img/ftm.svg";

import "./App.scss";
import { useEffect } from "react";

const Completionist = () => <span className="countdown">00:00</span>;

const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <span className="histories-box-header-clock">
        {zeroPad(minutes + hours * 60)}:{zeroPad(seconds)}
      </span>
    );
  }
};

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  return (
    <div className="App">
      <header className="header">
        <div className="container">
          <div className="header-top">
            <img src={headerTop} alt="" />
          </div>
          <div className="header-main">
            <div className="header-main-left">
              <img src={headerLeft} alt="" />
            </div>
            <div className="header-main-right">
              <img src={headerRight} alt="" />
              <div className="header-main-right-text">
                <h1 className="title">CoinPocketBot</h1>
                <div className="header-btn">
                  <a className="btn-primary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="18"
                      viewBox="0 0 23 18"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_1_33)">
                        <path
                          d="M4.86649 10.0875L7.70897 17.3178C7.70897 17.3178 8.06428 17.9942 8.44497 17.9942C8.82566 17.9942 14.4852 12.5831 14.4852 12.5831L20.7793 1.41113L4.968 8.22163L4.86649 10.0875Z"
                          fill="#C8DAEA"
                        />
                        <path
                          d="M8.63532 11.9417L8.08967 17.2711C8.08967 17.2711 7.86125 18.9037 9.6378 17.2711C11.4144 15.6384 13.1148 14.379 13.1148 14.379L8.63532 11.9417Z"
                          fill="#A9C6D8"
                        />
                        <path
                          d="M5.65325 10.1633L0.456833 8.60642C0.456833 8.60642 -0.16496 8.37318 0.0380741 7.8484C0.076143 7.73761 0.164971 7.65015 0.412419 7.48688C1.56083 6.75802 21.712 0.0991289 21.712 0.0991289C21.712 0.0991289 22.283 -0.0757982 22.6193 0.0408199C22.7716 0.093298 22.8731 0.157438 22.9556 0.373181C22.9873 0.454814 23 0.62391 23 0.793006C23 0.915455 22.981 1.03207 22.9683 1.207C22.8477 3.02624 19.1741 16.6122 19.1741 16.6122C19.1741 16.6122 18.952 17.4052 18.1652 17.4344C17.8797 17.4461 17.5308 17.3936 17.112 17.0612C15.5639 15.8426 10.2279 12.5423 9.04773 11.8134C8.98428 11.7726 8.96525 11.7201 8.95256 11.6676C8.93352 11.5918 9.02869 11.4985 9.02869 11.4985C9.02869 11.4985 18.3239 3.90671 18.5713 3.10787C18.5903 3.04374 18.5206 3.01458 18.419 3.04374C17.8036 3.24782 7.09987 9.46356 5.91973 10.1516C5.84994 10.1924 5.65325 10.1633 5.65325 10.1633Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1_33">
                          <rect width="23" height="18" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>Join in Telegram</span>
                  </a>
                  <div className="header-btn-socials">
                    <a
                      className="btn-secondary"
                      href="https://t.me/CoinPocketBotETH"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="23"
                        height="18"
                        viewBox="0 0 23 18"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_1_44)">
                          <path
                            d="M4.86649 10.0875L7.70897 17.3178C7.70897 17.3178 8.06428 17.9942 8.44497 17.9942C8.82566 17.9942 14.4852 12.5831 14.4852 12.5831L20.7793 1.41113L4.968 8.22163L4.86649 10.0875Z"
                            fill="#C8DAEA"
                          />
                          <path
                            d="M8.63532 11.9417L8.08967 17.2711C8.08967 17.2711 7.86125 18.9037 9.6378 17.2711C11.4144 15.6384 13.1148 14.379 13.1148 14.379L8.63532 11.9417Z"
                            fill="#A9C6D8"
                          />
                          <path
                            d="M5.65325 10.1633L0.456833 8.60642C0.456833 8.60642 -0.16496 8.37318 0.0380741 7.8484C0.076143 7.73761 0.164971 7.65015 0.412419 7.48688C1.56083 6.75802 21.712 0.0991289 21.712 0.0991289C21.712 0.0991289 22.283 -0.0757982 22.6193 0.0408199C22.7716 0.093298 22.8731 0.157438 22.9556 0.373181C22.9873 0.454814 23 0.62391 23 0.793006C23 0.915455 22.981 1.03207 22.9683 1.207C22.8477 3.02624 19.1741 16.6122 19.1741 16.6122C19.1741 16.6122 18.952 17.4052 18.1652 17.4344C17.8797 17.4461 17.5308 17.3936 17.112 17.0612C15.5639 15.8426 10.2279 12.5423 9.04773 11.8134C8.98428 11.7726 8.96525 11.7201 8.95256 11.6676C8.93352 11.5918 9.02869 11.4985 9.02869 11.4985C9.02869 11.4985 18.3239 3.90671 18.5713 3.10787C18.5903 3.04374 18.5206 3.01458 18.419 3.04374C17.8036 3.24782 7.09987 9.46356 5.91973 10.1516C5.84994 10.1924 5.65325 10.1633 5.65325 10.1633Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1_44">
                            <rect width="23" height="18" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span>Telegram</span>
                    </a>
                    <a
                      className="btn-secondary"
                      href="https://twitter.com/CoinPocketBot"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M7.54131 21.5053C16.5969 21.5053 21.5543 14 21.5543 7.49224C21.5543 7.28192 21.5483 7.0656 21.5423 6.85528C22.5038 6.15824 23.339 5.29294 24 4.30746C23.1167 4.69805 22.1673 4.96244 21.1698 5.08262C22.1853 4.47571 22.9664 3.50826 23.333 2.36054C22.3836 2.92539 21.326 3.334 20.2083 3.55633C19.307 2.6009 18.027 2 16.6089 2C13.8868 2 11.6815 4.20531 11.6815 6.92138C11.6815 7.30596 11.7236 7.68453 11.8077 8.04507C7.72158 7.84076 4.09214 5.88182 1.6645 2.90135C1.24387 3.62844 0.997496 4.47571 0.997496 5.37707C0.997496 7.08362 1.8688 8.59189 3.19079 9.47521C2.38558 9.45118 1.62243 9.22884 0.961442 8.85628C0.961442 8.87431 0.961442 8.89835 0.961442 8.91637C0.961442 11.302 2.66199 13.2909 4.90936 13.7416C4.49474 13.8558 4.06209 13.9159 3.61142 13.9159C3.29294 13.9159 2.98648 13.8858 2.68603 13.8257C3.31097 15.7847 5.1317 17.2028 7.28893 17.2449C5.6004 18.5668 3.47922 19.354 1.17176 19.354C0.775163 19.354 0.384577 19.33 0 19.2879C2.17526 20.688 4.75914 21.5053 7.54131 21.5053Z"
                          fill="white"
                        />
                      </svg>
                      <span>Twitter</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-bottom">
            <img src={headerBottom} alt="" />
          </div>
        </div>
      </header>

      <main>
        <section className="intro">
          {/* <img src={intro} alt="" /> */}
          <div className="intro-info">
            <div className="intro-info-header">
              <h3>All-in-one Pocket on Blockchain</h3>
              <p>
                Your comprehensive solution for managing and tracking various
                digital assets securely and conveniently. With CoinPocketBot,
                you can seamlessly track cryptocurrencies, NFTs, and other
                blockchain-based assets.
              </p>
            </div>

            <div className="intro-info-main">
              <div className="intro-info-left">
                <div className="intro-info-left-item" data-aos="fade-right">
                  <h3 className="intro-info-title box-padding intro-info-border-bottom">
                    Wallet Balance
                  </h3>
                  <p className="box-padding pb">332,471.00 $</p>
                </div>
                <div className="intro-info-left-item" data-aos="fade-right">
                  <h3 className="intro-info-title box-padding intro-info-border-bottom">
                    Wallet Balance By Chain
                  </h3>
                  <ul className="intro-info-left-item-list box-padding">
                    <li>
                      <div className="intro-info-token">
                        <img src={eth} alt="" />
                        <span>ETH</span>
                      </div>
                      <span className="">319,039 $</span>
                    </li>
                    <li>
                      <div className="intro-info-token">
                        <img src={ftm} alt="" />
                        <span>FTM</span>
                      </div>
                      <span className="">153,288 $</span>
                    </li>
                    <li>
                      <div className="intro-info-token">
                        <img src={arb} alt="" />
                        <span>ARB</span>
                      </div>
                      <span className="">5,746 $</span>
                    </li>
                    <li>
                      <div className="intro-info-token">
                        <img src={bsc} alt="" />
                        <span>BSC</span>
                      </div>
                      <span className="">0.00 $</span>
                    </li>
                    <li>
                      <div className="intro-info-token">
                        <img src={matic} alt="" />
                        <span>MATIC</span>
                      </div>
                      <span className="">0.00 $</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="intro-info-right" data-aos="fade-left">
                <h3 className="intro-info-title box-padding intro-info-border-bottom">
                  Token Balance
                </h3>
                <div className="intro-info-right-content box-padding">
                  <div className="intro-info-right-row intro-info-right-row-label">
                    <span className="intro-info-right-row-1 intro-info-subtitle">
                      Unit
                    </span>
                    <span className="intro-info-right-row-2 intro-info-subtitle">
                      Amount
                    </span>
                    <span className="intro-info-right-row-3 intro-info-subtitle">
                      Price
                    </span>
                    <span className="intro-info-right-row-4 intro-info-subtitle">
                      Total USD
                    </span>
                  </div>

                  <div className="intro-info-right-box">
                    <div className="intro-info-right-box-header">
                      <div className="intro-info-token">
                        <img src={eth} alt="" />
                        <span>ETH</span>
                      </div>
                    </div>

                    <div className="intro-info-right-box-main">
                      <div className="intro-info-right-row intro-info-right-box-main-item">
                        <span className="intro-info-right-row-1">$ETH</span>
                        <span className="intro-info-right-row-2">104.80</span>
                        <span className="intro-info-right-row-3">1,875.61</span>
                        <span className="intro-info-right-row-4">
                          196,572.32$
                        </span>
                      </div>
                      <div className="intro-info-right-row intro-info-right-box-main-item">
                        <span className="intro-info-right-row-1">$UNIDX</span>
                        <span className="intro-info-right-row-2">
                          15,188.71
                        </span>
                        <span className="intro-info-right-row-3">3.92</span>
                        <span className="intro-info-right-row-4">
                          59,532.45$
                        </span>
                      </div>
                      <div className="intro-info-right-row intro-info-right-box-main-item">
                        <span className="intro-info-right-row-1">
                          $WagieBot
                        </span>
                        <span className="intro-info-right-row-2">
                          26,055.87
                        </span>
                        <span className="intro-info-right-row-3">1.47</span>
                        <span className="intro-info-right-row-4">
                          38,191.00$
                        </span>
                      </div>
                      <div className="intro-info-right-row intro-info-right-box-main-item">
                        <span className="intro-info-right-row-1">$imgnAI</span>
                        <span className="intro-info-right-row-2">
                          1,745,766
                        </span>
                        <span className="intro-info-right-row-3">0.01417</span>
                        <span className="intro-info-right-row-4">
                          24,743.23$
                        </span>
                      </div>
                    </div>

                    <div className="intro-info-right-box-main-mb">
                      <div className="intro-info-right-row-mb intro-info-right-box-main-item">
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Unit
                          </span>
                          <span>$ETH</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Amount
                          </span>
                          <span>104.80</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Price
                          </span>
                          <span>1,875.61</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Total USD
                          </span>
                          <span>196,572.32$</span>
                        </div>
                      </div>

                      <div className="intro-info-right-row-mb intro-info-right-box-main-item">
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Unit
                          </span>
                          <span>$UNIDX</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Amount
                          </span>
                          <span>15,188.71</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Price
                          </span>
                          <span>3.92</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Total USD
                          </span>
                          <span>59,532.45$</span>
                        </div>
                      </div>

                      <div className="intro-info-right-row-mb intro-info-right-box-main-item">
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Unit
                          </span>
                          <span>$WagieBot</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Amount
                          </span>
                          <span>26,055.87</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Price
                          </span>
                          <span>1.47</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Total USD
                          </span>
                          <span>38,191.00$</span>
                        </div>
                      </div>

                      <div className="intro-info-right-row-mb intro-info-right-box-main-item">
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Unit
                          </span>
                          <span>$imgnAI</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Amount
                          </span>
                          <span>1,745,766</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Price
                          </span>
                          <span>0.01417</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Total USD
                          </span>
                          <span>24,743.23$</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="intro-info-right-box">
                    <div className="intro-info-right-box-header">
                      <div className="intro-info-token">
                        <img src={ftm} alt="" />
                        <span>FTM</span>
                      </div>
                    </div>

                    <div className="intro-info-right-box-main">
                      <div className="intro-info-right-row intro-info-right-box-main-item">
                        <span className="intro-info-right-row-1">$ELITE</span>
                        <span className="intro-info-right-row-2">229.22</span>
                        <span className="intro-info-right-row-3">630.05</span>
                        <span className="intro-info-right-row-4">
                          144,423.49$
                        </span>
                      </div>
                      <div className="intro-info-right-row intro-info-right-box-main-item">
                        <span className="intro-info-right-row-1">$TREEB</span>
                        <span className="intro-info-right-row-2">
                          1,250,001
                        </span>
                        <span className="intro-info-right-row-3">0.004638</span>
                        <span className="intro-info-right-row-4">
                          5,797.52$
                        </span>
                      </div>
                      <div className="intro-info-right-row intro-info-right-box-main-item">
                        <span className="intro-info-right-row-1">$USDT</span>
                        <span className="intro-info-right-row-2">30049</span>
                        <span className="intro-info-right-row-3">
                          0.99997500
                        </span>
                        <span className="intro-info-right-row-4">
                          30048.31$
                        </span>
                      </div>
                    </div>

                    <div className="intro-info-right-box-main-mb">
                      <div className="intro-info-right-row-mb intro-info-right-box-main-item">
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Unit
                          </span>
                          <span>$ELITE</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Amount
                          </span>
                          <span>229.22</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Price
                          </span>
                          <span>630.05</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Total USD
                          </span>
                          <span>144,423.49$</span>
                        </div>
                      </div>

                      <div className="intro-info-right-row-mb intro-info-right-box-main-item">
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Unit
                          </span>
                          <span>$TREEB</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Amount
                          </span>
                          <span>1,250,001</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Price
                          </span>
                          <span>0.004638</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Total USD
                          </span>
                          <span>5,797.52$</span>
                        </div>
                      </div>

                      <div className="intro-info-right-row-mb intro-info-right-box-main-item">
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Unit
                          </span>
                          <span>$OATH</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Amount
                          </span>
                          <span>60,055.55</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Price
                          </span>
                          <span>0.05107</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Total USD
                          </span>
                          <span>3,066.99$</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="intro-info-right-box">
                    <div className="intro-info-right-box-header">
                      <div className="intro-info-token">
                        <img src={arb} alt="" />
                        <span>ARB</span>
                      </div>
                    </div>

                    <div className="intro-info-right-box-main">
                      <div className="intro-info-right-row intro-info-right-box-main-item">
                        <span className="intro-info-right-row-1">$USDC</span>
                        <span className="intro-info-right-row-2">5,587.99</span>
                        <span className="intro-info-right-row-3">
                          0.99990000
                        </span>
                        <span className="intro-info-right-row-4">
                          5,587.44$
                        </span>
                      </div>
                      <div className="intro-info-right-row intro-info-right-box-main-item">
                        <span className="intro-info-right-row-1">$ETH</span>
                        <span className="intro-info-right-row-2">0.04873</span>
                        <span className="intro-info-right-row-3">1,875.61</span>
                        <span className="intro-info-right-row-4">91.40$</span>
                      </div>
                      <div className="intro-info-right-row intro-info-right-box-main-item">
                        <span className="intro-info-right-row-1">$MAGIC</span>
                        <span className="intro-info-right-row-2">92.2580</span>
                        <span className="intro-info-right-row-3">0.7279</span>
                        <span className="intro-info-right-row-4">67.16$</span>
                      </div>
                    </div>

                    <div className="intro-info-right-box-main-mb">
                      <div className="intro-info-right-row-mb intro-info-right-box-main-item">
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Unit
                          </span>
                          <span>$USDC</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Amount
                          </span>
                          <span>5,587.99</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Price
                          </span>
                          <span>0.99990000</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Total USD
                          </span>
                          <span>5,587.44$</span>
                        </div>
                      </div>

                      <div className="intro-info-right-row-mb intro-info-right-box-main-item">
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Unit
                          </span>
                          <span>$ETH</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Amount
                          </span>
                          <span>0.04873</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Price
                          </span>
                          <span>1,875.61</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Total USD
                          </span>
                          <span>91.40$</span>
                        </div>
                      </div>

                      <div className="intro-info-right-row-mb intro-info-right-box-main-item">
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Unit
                          </span>
                          <span>$MAGIC</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Amount
                          </span>
                          <span>92.2580</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Price
                          </span>
                          <span>0.7279</span>
                        </div>
                        <div className="intro-info-right-row-mb-item">
                          <span className="intro-info-right-row-mb-item-label">
                            Total USD
                          </span>
                          <span>67.16$</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="history-wrapper">
          <div className="history">
            <div className="history-header">
              <div className="history-header-logo">
                <img src={history} alt="" />
              </div>
              <h3 className="history-header-title">Transaction history</h3>
            </div>

            <div className="histories">
              <div className="histories-box" data-aos="fade-right">
                <div className="histories-box-header">
                  <div className="histories-box-header-left">
                    <img src={clock} alt="" />

                    <Countdown
                      date={Date.now() + 5400000}
                      renderer={renderer}
                    />
                  </div>
                  <div className="histories-box-header-right">
                    <span>0xd279…0ccd</span>
                    <img src={eth} alt="" />
                  </div>
                </div>
                <div className="histories-box-main">
                  <div className="histories-box-main-item">
                    <h3 className="histories-box-main-item-title">
                      Transaction history
                    </h3>
                    <div className="histories-box-main-item-box">
                      <div className="histories-box-main-item-box-left">
                        <img src={uni} alt="" />
                      </div>
                      <div className="histories-box-main-item-box-right">
                        <span>Uniswap V2</span>
                        <span className="text-gray">
                          swapExactTokensForETHSupportingFeeOnTransferTokens...
                        </span>
                      </div>
                    </div>

                    <div className="histories-box-main-item-box">
                      <div className="histories-box-main-item-box-left">
                        <img src={circle} alt="" />
                      </div>
                      <div className="histories-box-main-item-box-right">
                        <span>
                          +1,782,000 XCHAIN
                          <span className="text-gray">($0)</span>
                        </span>
                      </div>
                    </div>
                    <div className="histories-box-main-item-box">
                      <div className="histories-box-main-item-box-left">
                        <img src={eth} alt="" />
                      </div>
                      <div className="histories-box-main-item-box-right">
                        <span>
                          -0.1738 ETH{" "}
                          <span className="text-gray">($323.11)</span>
                        </span>
                      </div>
                    </div>
                    <div className="histories-box-main-item-box">
                      <div className="histories-box-main-item-box-left">
                        <img src={usdt} alt="" />
                      </div>
                      <div className="histories-box-main-item-box-right">
                        <span>
                          0.0702ETH <span className="text-gray">($130.47)</span>
                        </span>
                        <span className="text-gray">Gas Fee</span>
                      </div>
                    </div>
                  </div>
                  <div className="histories-box-main-item">
                    <h3 className="histories-box-main-item-title">
                      Profit history
                    </h3>
                    <span className="text-14">Primary Trade</span>

                    <div className="histories-box-main-item-box-small">
                      <div className="histories-box-main-item-box-small-col">
                        <div className="histories-box-main-item-box-small-row">
                          <img src={usdtv2} alt="" />
                          <span>XCHAIN</span>
                        </div>
                        <div className="histories-box-main-item-box-small-row">
                          <img src={rocket} alt="" />
                          <span> +111.27%</span>
                        </div>
                      </div>
                    </div>
                    <div className="histories-box-main-item-box-small">
                      <div className="histories-box-main-item-box-small-col">
                        <div className="histories-box-main-item-box-small-row">
                          <img src={initial} alt="" />
                          <span>Initial:</span>
                          <span className="text-pop-primary">0.1738 ETH</span>
                        </div>
                      </div>
                    </div>
                    <div className="histories-box-main-item-box-small">
                      <div className="histories-box-main-item-box-small-col">
                        <div className="histories-box-main-item-box-small-row">
                          <img src={worth} alt="" />
                          <span>Worth::</span>
                          <span className="text-pop-primary">0.3672 ETH</span>
                        </div>
                      </div>
                    </div>

                    <span className="text-14">Other Trades</span>

                    <div className="histories-box-main-item-box-small">
                      <div className="histories-box-main-item-box-small-col-grid">
                        <div className="histories-box-main-item-box-small-row-grid histories-box-main-item-box-small-row-grid-1">
                          <span>01</span>
                        </div>
                        <div className="histories-box-main-item-box-small-row-grid histories-box-main-item-box-small-row-grid-2">
                          <img src={usdtv2} alt="" />
                          <span> $ X 2.0</span>
                        </div>
                        <div className="histories-box-main-item-box-small-row-grid histories-box-main-item-box-small-row-grid-3">
                          <img src={rocket} alt="" />
                          <span> -6.00%</span>
                        </div>
                        <div className="histories-box-main-item-box-small-row-grid histories-box-main-item-box-small-row-grid-4">
                          <img src={clock} alt="" />
                          <span> 84:02</span>
                        </div>
                      </div>
                    </div>

                    <div className="histories-box-main-item-box-small">
                      <div className="histories-box-main-item-box-small-col-grid">
                        <div className="histories-box-main-item-box-small-row-grid histories-box-main-item-box-small-row-grid-1">
                          <span>02</span>
                        </div>
                        <div className="histories-box-main-item-box-small-row-grid histories-box-main-item-box-small-row-grid-2">
                          <img src={usdtv2} alt="" />
                          <span> $ X 2.0</span>
                        </div>
                        <div className="histories-box-main-item-box-small-row-grid histories-box-main-item-box-small-row-grid-3">
                          <img src={rocket} alt="" />
                          <span> +51.00%</span>
                        </div>
                        <div className="histories-box-main-item-box-small-row-grid histories-box-main-item-box-small-row-grid-4">
                          <img src={clock} alt="" />
                          <span> 84:02</span>
                        </div>
                      </div>
                    </div>

                    <div className="histories-box-main-item-box-small">
                      <div className="histories-box-main-item-box-small-col-grid">
                        <div className="histories-box-main-item-box-small-row-grid histories-box-main-item-box-small-row-grid-1">
                          <span>03</span>
                        </div>
                        <div className="histories-box-main-item-box-small-row-grid histories-box-main-item-box-small-row-grid-2">
                          <img src={usdtv2} alt="" />
                          <span> $ X 2.0</span>
                        </div>
                        <div className="histories-box-main-item-box-small-row-grid histories-box-main-item-box-small-row-grid-3">
                          <img src={rocket} alt="" />
                          <span> +36.00%</span>
                        </div>
                        <div className="histories-box-main-item-box-small-row-grid histories-box-main-item-box-small-row-grid-4">
                          <img src={clock} alt="" />
                          <span> 84:02</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="histories-box" data-aos="fade-left">
                <div className="histories-box-header">
                  <div className="histories-box-header-left">
                    <img src={clock} alt="" />
                    <Countdown
                      date={Date.now() + 5400000}
                      renderer={renderer}
                    />
                  </div>
                  <div className="histories-box-header-right">
                    <span>0x6d19…961c</span>
                    <img src={eth} alt="" />
                  </div>
                </div>
                <div className="histories-box-main">
                  <div className="histories-box-main-item">
                    <h3 className="histories-box-main-item-title">
                      Transaction history
                    </h3>
                    <div className="histories-box-main-item-box">
                      <div className="histories-box-main-item-box-left">
                        <img src={uni} alt="" />
                      </div>
                      <div className="histories-box-main-item-box-right">
                        <span>Uniswap V2</span>
                        <span className="text-gray">
                          swapExactTokensForETHSupportingFeeOnTransferTokens...
                        </span>
                      </div>
                    </div>

                    <div className="histories-box-main-item-box">
                      <div className="histories-box-main-item-box-left">
                        <img src={circle} alt="" />
                      </div>
                      <div className="histories-box-main-item-box-right">
                        <span>
                          -1,782,000 XCHAIN{" "}
                          <span className="text-gray">($0)</span>
                        </span>
                      </div>
                    </div>
                    <div className="histories-box-main-item-box">
                      <div className="histories-box-main-item-box-left">
                        <img src={eth} alt="" />
                      </div>
                      <div className="histories-box-main-item-box-right">
                        <span>
                          +0.3672 ETH{" "}
                          <span className="text-gray">($1,944.31)</span>
                        </span>
                      </div>
                    </div>
                    <div className="histories-box-main-item-box">
                      <div className="histories-box-main-item-box-left">
                        <img src={usdt} alt="" />
                      </div>
                      <div className="histories-box-main-item-box-right">
                        <span>
                          0.0077ETH <span className="text-gray">($14.31)</span>
                        </span>
                        <span className="text-gray">Gas Fee</span>
                      </div>
                    </div>
                  </div>
                  <div className="histories-box-main-item">
                    <h3 className="histories-box-main-item-title">
                      Profit history
                    </h3>
                    <span className="text-14">Primary Trade</span>

                    <div className="histories-box-main-item-box-small">
                      <div className="histories-box-main-item-box-small-col">
                        <div className="histories-box-main-item-box-small-row">
                          <img src={usdtv2} alt="" />
                          <span>UFO</span>
                        </div>
                        <div className="histories-box-main-item-box-small-row">
                          <img src={rocket} alt="" />
                          <span> +111.27%</span>
                        </div>
                      </div>
                    </div>
                    <div className="histories-box-main-item-box-small">
                      <div className="histories-box-main-item-box-small-col">
                        <div className="histories-box-main-item-box-small-row">
                          <img src={initial} alt="" />
                          <span>Initial:</span>
                          <span className="text-pop-primary">0.1738 ETH</span>
                        </div>
                      </div>
                    </div>
                    <div className="histories-box-main-item-box-small">
                      <div className="histories-box-main-item-box-small-col">
                        <div className="histories-box-main-item-box-small-row">
                          <img src={worth} alt="" />
                          <span>Worth::</span>
                          <span className="text-pop-primary">0.3672 ETH</span>
                        </div>
                      </div>
                    </div>

                    <span className="text-14">Other Trades</span>

                    <div className="histories-box-main-item-box-small">
                      <div className="histories-box-main-item-box-small-col-grid">
                        <div className="histories-box-main-item-box-small-row-grid histories-box-main-item-box-small-row-grid-1">
                          <span>01</span>
                        </div>
                        <div className="histories-box-main-item-box-small-row-grid histories-box-main-item-box-small-row-grid-2">
                          <img src={usdtv2} alt="" />
                          <span> $ X 2.0</span>
                        </div>
                        <div className="histories-box-main-item-box-small-row-grid histories-box-main-item-box-small-row-grid-3">
                          <img src={rocket} alt="" />
                          <span> +26.00%</span>
                        </div>
                        <div className="histories-box-main-item-box-small-row-grid histories-box-main-item-box-small-row-grid-4">
                          <img src={clock} alt="" />
                          <span> 84:02</span>
                        </div>
                      </div>
                    </div>

                    <div className="histories-box-main-item-box-small">
                      <div className="histories-box-main-item-box-small-col-grid">
                        <div className="histories-box-main-item-box-small-row-grid histories-box-main-item-box-small-row-grid-1">
                          <span>02</span>
                        </div>
                        <div className="histories-box-main-item-box-small-row-grid histories-box-main-item-box-small-row-grid-2">
                          <img src={usdtv2} alt="" />
                          <span> $ X 2.0</span>
                        </div>
                        <div className="histories-box-main-item-box-small-row-grid histories-box-main-item-box-small-row-grid-3">
                          <img src={rocket} alt="" />
                          <span> +72.00%</span>
                        </div>
                        <div className="histories-box-main-item-box-small-row-grid histories-box-main-item-box-small-row-grid-4">
                          <img src={clock} alt="" />
                          <span> 84:02</span>
                        </div>
                      </div>
                    </div>

                    <div className="histories-box-main-item-box-small">
                      <div className="histories-box-main-item-box-small-col-grid">
                        <div className="histories-box-main-item-box-small-row-grid histories-box-main-item-box-small-row-grid-1">
                          <span>03</span>
                        </div>
                        <div className="histories-box-main-item-box-small-row-grid histories-box-main-item-box-small-row-grid-2">
                          <img src={usdtv2} alt="" />
                          <span> $ X 2.0</span>
                        </div>
                        <div className="histories-box-main-item-box-small-row-grid histories-box-main-item-box-small-row-grid-3">
                          <img src={rocket} alt="" />
                          <span> -10.00%</span>
                        </div>
                        <div className="histories-box-main-item-box-small-row-grid histories-box-main-item-box-small-row-grid-4">
                          <img src={clock} alt="" />
                          <span> 84:02</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer className="footer">
        <div className="footer-right-text">
          <h1 className="title">CoinPocketBot</h1>
          <h4 className="text-black">owner@coinpocketbot.com</h4>
          <h4 className="text-black">
            0xA8B3F8b45b3C0822688f514bb1fB99D086222615
          </h4>
          <div className="header-btn">
            <a className="btn-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="18"
                viewBox="0 0 23 18"
                fill="none"
              >
                <g clip-path="url(#clip0_1_33)">
                  <path
                    d="M4.86649 10.0875L7.70897 17.3178C7.70897 17.3178 8.06428 17.9942 8.44497 17.9942C8.82566 17.9942 14.4852 12.5831 14.4852 12.5831L20.7793 1.41113L4.968 8.22163L4.86649 10.0875Z"
                    fill="#C8DAEA"
                  />
                  <path
                    d="M8.63532 11.9417L8.08967 17.2711C8.08967 17.2711 7.86125 18.9037 9.6378 17.2711C11.4144 15.6384 13.1148 14.379 13.1148 14.379L8.63532 11.9417Z"
                    fill="#A9C6D8"
                  />
                  <path
                    d="M5.65325 10.1633L0.456833 8.60642C0.456833 8.60642 -0.16496 8.37318 0.0380741 7.8484C0.076143 7.73761 0.164971 7.65015 0.412419 7.48688C1.56083 6.75802 21.712 0.0991289 21.712 0.0991289C21.712 0.0991289 22.283 -0.0757982 22.6193 0.0408199C22.7716 0.093298 22.8731 0.157438 22.9556 0.373181C22.9873 0.454814 23 0.62391 23 0.793006C23 0.915455 22.981 1.03207 22.9683 1.207C22.8477 3.02624 19.1741 16.6122 19.1741 16.6122C19.1741 16.6122 18.952 17.4052 18.1652 17.4344C17.8797 17.4461 17.5308 17.3936 17.112 17.0612C15.5639 15.8426 10.2279 12.5423 9.04773 11.8134C8.98428 11.7726 8.96525 11.7201 8.95256 11.6676C8.93352 11.5918 9.02869 11.4985 9.02869 11.4985C9.02869 11.4985 18.3239 3.90671 18.5713 3.10787C18.5903 3.04374 18.5206 3.01458 18.419 3.04374C17.8036 3.24782 7.09987 9.46356 5.91973 10.1516C5.84994 10.1924 5.65325 10.1633 5.65325 10.1633Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1_33">
                    <rect width="23" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span>Join in Telegram</span>
            </a>
            <div className="header-btn-socials">
              <a
                className="btn-secondary"
                href="https://t.me/CoinPocketBotETH"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="18"
                  viewBox="0 0 23 18"
                  fill="none"
                >
                  <g clip-path="url(#clip0_1_44)">
                    <path
                      d="M4.86649 10.0875L7.70897 17.3178C7.70897 17.3178 8.06428 17.9942 8.44497 17.9942C8.82566 17.9942 14.4852 12.5831 14.4852 12.5831L20.7793 1.41113L4.968 8.22163L4.86649 10.0875Z"
                      fill="#C8DAEA"
                    />
                    <path
                      d="M8.63532 11.9417L8.08967 17.2711C8.08967 17.2711 7.86125 18.9037 9.6378 17.2711C11.4144 15.6384 13.1148 14.379 13.1148 14.379L8.63532 11.9417Z"
                      fill="#A9C6D8"
                    />
                    <path
                      d="M5.65325 10.1633L0.456833 8.60642C0.456833 8.60642 -0.16496 8.37318 0.0380741 7.8484C0.076143 7.73761 0.164971 7.65015 0.412419 7.48688C1.56083 6.75802 21.712 0.0991289 21.712 0.0991289C21.712 0.0991289 22.283 -0.0757982 22.6193 0.0408199C22.7716 0.093298 22.8731 0.157438 22.9556 0.373181C22.9873 0.454814 23 0.62391 23 0.793006C23 0.915455 22.981 1.03207 22.9683 1.207C22.8477 3.02624 19.1741 16.6122 19.1741 16.6122C19.1741 16.6122 18.952 17.4052 18.1652 17.4344C17.8797 17.4461 17.5308 17.3936 17.112 17.0612C15.5639 15.8426 10.2279 12.5423 9.04773 11.8134C8.98428 11.7726 8.96525 11.7201 8.95256 11.6676C8.93352 11.5918 9.02869 11.4985 9.02869 11.4985C9.02869 11.4985 18.3239 3.90671 18.5713 3.10787C18.5903 3.04374 18.5206 3.01458 18.419 3.04374C17.8036 3.24782 7.09987 9.46356 5.91973 10.1516C5.84994 10.1924 5.65325 10.1633 5.65325 10.1633Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_44">
                      <rect width="23" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span>Telegram</span>
              </a>
              <a
                className="btn-secondary"
                href="https://twitter.com/CoinPocketBot"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7.54131 21.5053C16.5969 21.5053 21.5543 14 21.5543 7.49224C21.5543 7.28192 21.5483 7.0656 21.5423 6.85528C22.5038 6.15824 23.339 5.29294 24 4.30746C23.1167 4.69805 22.1673 4.96244 21.1698 5.08262C22.1853 4.47571 22.9664 3.50826 23.333 2.36054C22.3836 2.92539 21.326 3.334 20.2083 3.55633C19.307 2.6009 18.027 2 16.6089 2C13.8868 2 11.6815 4.20531 11.6815 6.92138C11.6815 7.30596 11.7236 7.68453 11.8077 8.04507C7.72158 7.84076 4.09214 5.88182 1.6645 2.90135C1.24387 3.62844 0.997496 4.47571 0.997496 5.37707C0.997496 7.08362 1.8688 8.59189 3.19079 9.47521C2.38558 9.45118 1.62243 9.22884 0.961442 8.85628C0.961442 8.87431 0.961442 8.89835 0.961442 8.91637C0.961442 11.302 2.66199 13.2909 4.90936 13.7416C4.49474 13.8558 4.06209 13.9159 3.61142 13.9159C3.29294 13.9159 2.98648 13.8858 2.68603 13.8257C3.31097 15.7847 5.1317 17.2028 7.28893 17.2449C5.6004 18.5668 3.47922 19.354 1.17176 19.354C0.775163 19.354 0.384577 19.33 0 19.2879C2.17526 20.688 4.75914 21.5053 7.54131 21.5053Z"
                    fill="white"
                  />
                </svg>
                <span>Twitter</span>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
